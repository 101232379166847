function nl2br(str, replaceMode, isXhtml) {

    var breakTag = (isXhtml) ? '<br />' : '<br>';
    var replaceStr = (replaceMode) ? '$1' + breakTag : '$1' + breakTag + '$2';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
}

function toogleMenuQuick() {
    $(".menuquick .lst").hasClass("hide") ? ($(".menuquick .lst").removeClass("hide"), $(".menuquick label").removeClass("tg")) : ($(".menuquick .lst").addClass("hide"), $(".menuquick label").addClass("tg"))
}

function genMenuDetail() {
    var n, t, i;
    $(".body-contents").length > 0 && $(".body-contents>h3").length > 1 && (n = "<div class='menuquick'>", n += "<label onclick='toogleMenuQuick()'>Xem nhanh<\/label>", t = 1, i = 1, n += "<ul class='lst'>", $(".body-contents").children().each(function () {
        var f = $(this).parent().attr("class"), u, r;
        f !== "infor" && ($(this).is("h3") || $(this).is("h4")) && (u = $(this).text(), $(this).is("h3") ? ($(this).prop("id", "hmenuid" + t), n += "<a href='#hmenuid" + t + "'>" + u + "<\/a>", t += 1) : (r = u, r.startsWith("-") && (r = r.replace("-", "").trim()), $(this).prop("id", "subhmenuid" + i), n += '<li class="SubQuickMenu"><a  href=\'#subhmenuid' + i + "'>" + r + "<\/a><\/li>", i += 1))
    }), n += "<\/ul>", n += "<\/div>", n += '<div class="list-faq list-scroll">', n += '<div class="middle">', n += '<div class="btn-faq sticky">', n += '<span class="stickyname">Xem nhanh<\/span>', n += '<span class="stickytitle"><\/span>', n += '<b class="collapse"><\/b>', n += "<\/div>", n += "<div class='list-item-fast-view'>", n += "<div class='lst-fast-view'>", t = 1, i = 1, $(".body-contents").children().each(function () {
        var u = $(this).parent().attr("class"), r;
        u !== "infor" && ($(this).is("h3") || $(this).is("h4")) && (r = $(this).text(), $(this).is("h3") ? (n += "<a href='#hmenuid" + t + "' data-id='#hmenuid" + t + "' data-name='" + r + "'>" + r + "<\/a>", t += 1) : (n += "<a href='#subhmenuid" + i + "'>" + r + "<\/a>", i += 1))
    }), n += "<\/div>", n += "<\/div>", n += "<\/div>", $(".body-contents h2").first().after(n))
}

function printInvoice(invoice) {
    var printContents = document.getElementById(invoice).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
}

function number_format(int) {
    if (int > 999 || int < -999) {
        return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return int;
    }
}

function flash(obj) {

    if (obj.hasOwnProperty('errors')) {
        $.each(obj.errors, function (key, value) {
            toastr['error'](value);
        });
    } else if (obj.type) {

        toastr[obj.type](obj.message);
    } else if (obj.message) {
        toastr['error'](obj.message);
    } else
        toastr['warning']('Đã có lỗi xảy ra');

    if (obj.hasOwnProperty('url') && (obj.url != null && obj.url != '')) {
        window.open(obj.url, obj.target)
    }

};

function changeSeo() {
    $('.change-seo').slideToggle();
};

function ajaxForm(ele) {
    $(ele).ajaxSubmit({
        headers: {
            "X-CSRF-Token": $('meta[name=_token]').attr('content')
        },
        beforeSubmit: function (formData, jqForm, options) {
            $(ele).find('[type=submit]').attr('disabled');

        },
        success: function (responseText, statusText, xhr, $form) {


        },
        error: function (xhr, status, errMsg, $form) {

        },
        complete: function (xhr, statusText, $form) {

            $(ele).find('[type=submit]').attr('disabled', false);

            var result = xhr.responseText;

            try {
                result = $.parseJSON(result);
            } catch(e) {
                console.log('invalid json response');
                $('#ajax-modal').html(result).modal();
                return;
            }

            flash(result);

            if (result.type == 'success' || result.type == 'info') {

                if ($form.data('reset') == true)
                    $form.resetForm();

                $('#ajax-modal').modal('hide');

                try {
                    $table.bootstrapTable('refresh', {silent: true});
                } catch(e) {
                }
            }

        },

    });
    return false;
};

$(document).on('submit', '.ajax-form', function (e) {
    e.preventDefault();

    ajaxForm(this);
    return false;
});
$(document).ready(function () {
    $(document).on('click', '.view-image', function () {
        var image = $(this).attr('data-image');
        $('#viewImage').modal('show');
        $('.showImage').attr('src', image);
    });
    $('[data-toggle="tab"]').on('click', function (e) {
        e.preventDefault();
        var pane = $(this).attr('href');

        $('.tab-pane').not('.tab-custom').removeClass('active').hide();
        $(pane).addClass('active').show();
    });

    $('body').on('click', '[data-toggle="custom"]', function (e) {
        e.preventDefault();
        var pane = $(this).attr('href');

        $(this).closest('form').find('a[data-toggle="custom"]').removeClass('active');
        $(this).addClass('active');

        $(this).closest('form').find('.tab-custom, .tab-pane').removeClass('active').hide();
        $(this).closest('form').find('.nav-link').filter("[href='" + pane + "']").addClass('active');
        $(this).closest('form').find(pane).addClass('active').show();
    });

    var price = document.getElementById('price');
    var sale = document.getElementById('price_sale');
    var format_price = document.getElementById('format-price');
    var format_price_sale = document.getElementById('format-price-sale');

    $(price).on('keyup', function () {
        var value = $(this).val();
        if (value > 99)
            value = value.replace(/^0+/, '');

        $(this).val(number_format(value));
        value = value.replaceAll(',', '');

        $(format_price).val(value);
    });

    $(sale).on('keyup', function () {
        var value = $(this).val();
        if (value > 99)
            value = value.replace(/^0+/, '');

        $(this).val(number_format(value));
        value = value.replaceAll(',', '');
        $(format_price_sale).val(value);
    });

    genMenuDetail();

    $('.change-seo').hide();
});

